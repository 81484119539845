<template>
   <div>
      <!-- Breadcrumb start -->
       <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Privacy Policy</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><a href="/">Home</a></li>
                           <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- Terms start -->
      <section class="terms_sec">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="terms_box">
                     <p class="text-center your_privacy">
                        <span class="font_bold">Your privacy is important to PBE. This privacy statement provides information about the information that PBE collects, and the ways in which PBE uses that information.</span>
                     </p>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">
                           What Information Do We Collect?
                        </h6>
                        <p>
                           If you fill out surveys, ergo audits, request services, or subscribe to our blog, we may collect contact information pertaining to your workplace, including: your name, work email address and work phone number. In addition, when you take our self assessment or are the subject of an ergonomic evaluation we may also collect information associated with your work location, department, and general well-being. If you are the subject of a virtual (remote) evaluation, or any other form of virtual assessment, PBE neither records video or audio nor captures photos. PBE’s evaluators can neither record nor capture your screen content.
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">How We Use The Information We Collect</h6>
                        <p>
                           Your contact information is used to provide you with access to a secure account within PBE’s database, summaries of any self-assessments, ergonomic evaluations, surveys, audits, and service requests in which you participate, as well as to reset your account password, if and when necessary. In addition, your information may also be used to follow up with you and/or your organization about your needs.
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">How Do We Share Or Transfer Data?</h6>
                        <p>
                           Your information, as defined above, may be shared with designated members of your organization for internal use only. We will never sell or share your information with any outside organization.
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6"></h6>
                        <p>
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">Your Options For Data Collection And Use</h6>
                        <p>
                           The required questions during the registration process, self-assessment, or ergonomic evaluation are used to provide services to both you and your organization. All information collected will be considered discretionary and entirely optional.
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">Data Security: Storage, Transmission & Deletion</h6>
                        <p>
                           All data is stored and transmitted in compliance with HIPAA, GDPR and CPRA regulations, including standard levels of encryption. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and will notify you of the security incident in accordance with applicable laws and regulations.
                        </p>
                        <p>
                           All collected data is stored and processed in the US in HIPAA, GDPR and CPRA compliant servers. 
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">How Long Does PBE Hang On To Your Data?</h6>
                        <p>
                           PBE will retain your data for a period of 30 days subsequent to termination of services, unless otherwise requested. Please note there may be a data storage fee for requests past 30 days.
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">User Data Protection</h6>
                        <p>
                           Subjects specifically located in the European Union (GDPR) and California (CPRA); and all other geographic and economic regions are advised of the following rights.
                        </p>
                        <p>
                        <ul>
                           <li>
                              <p>You may request access to and rectification or erasure of personal data concerning you.</p>
                           </li>
                           <li>
                              <p>You may request a restriction of data processing concerning you.</p>
                           </li>
                           <li>
                              <p>You may request the personal data which you have provided us concerning you, and you may transmit those data to another controller without hindrance from us.</p>
                           </li>
                           <li>
                              <p>You may object at any time to our processing of personal data concerning you and you may withdraw your consent for future processing.</p>
                           </li>
                           <li>
                              <p>If you are a subject in the European Union, and you believe that our processing of personal data relating to you infringes the EU’s General Data Protection Regulation, you may lodge a complaint with your Member State’s designated supervisory authority.</p>
                           </li>
                        </ul>
                        </p>
                        <p>
                           Should you want to exercise your right to alter or remove your identifying information, please contact us at <a href="mailto:support@pbergo.com" class="font_bold text_primary"> support@pbergo.com</a>
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">Links To Other Sites</h6>
                        <p>
                           In order to provide you with our services, Pbergo.com may provide access to resources and links to other websites. When you access a non-Pbergo.com website, you leave the Pbergo.com website and understand that it is independent from Pbergo.com, and that Pbergo.com has not reviewed nor is it responsible for the content or your information collection of any linked websites. Pbergo.com makes no representations whatsoever about such resources or other websites. The inclusion of any link to a website does not imply endorsement by Pbergo.com of the website or their entities, products, or services. All warranties, conditions, or other terms expressed or implied as to any such linked website, including without limitation as to accuracy, ownership, validity, or legality of any content of a linked website, are hereby excluded. For a list of third-party sites in order to access their privacy policies, please contact us at:
                           <a href="mailto:support@pbergo.com" class="font_bold text_primary"> support@pbergo.com</a>
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">Changes And Updates To This Privacy Policy</h6>
                        <p>
                           From time to time, we may make changes to our privacy policy. In this event and to the best of our ability, we will notify you of any changes which may impact the collection, storage, and transmission of your data and your rights as it pertains to your data.
                        </p>
                     </div>
                     <div class="privacy_inner_box">
                        <h6 class="heading_6 text-uppercase">Contacting Us</h6>
                        <p>
                           If you have any questions or comments please contact us at 
                           <a href="mailto:support@pbergo.com" class="font_bold text_primary"> support@pbergo.com</a>
                        </p>
                     </div>
                     <p class="text-center privacy_text">
                        <strong>Terms of Service</strong> can be viewed by <router-link :to="{ name: 'terms_of_service' }">
                           <span class="font_bold">clicking here.</span> 
                        </router-link>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Terms end -->
   </div>
</template>
<script>
   export default {
      name: "privacy_policy"
   }
</script>